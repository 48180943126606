import Web3 from "web3";
import Units from "ethereumjs-units";

/// Crowdsale Contract
const CROWDSALE_CONTRACT = "NULSd6HgpTQVSzYXyAKhaHnZbguSb557HC5Cu";
const CROWDSALE_CONTRACT2 = "NULSd6HgoiZzwNtZT2FhJV4uBe7RZHXGSg19X";
const CROWDSALE_CONTRACT3 = "NULSd6Hgtnm49VJezUdp8kCor5i3d7sYpLk7b";

/// Nulswap Token
const NULSWAP_TOKEN = "NULSd6HgwWuVkqzk8fMommkv5xMa8dkGBLXc8";

export default class NulsConnector {

	// eslint-disable-next-line no-useless-constructor
	constructor() {
		this.init = false;
	}

	async initialize() {
		try {
			//this.web3 = await this.getWeb3();
			this.account = await window.nabox.createSession();
			this.init = true;
			return this;
		}
		catch (error) {
			throw error;
		}
	}

  async getChainID() {
	return "0x0"; //window.nabox.request({ method: 'eth_accounts' });
  }

  async getAccount(idx) {
	return window.nabox.createSession();
  }

  setAccount(account) {
	  this.account = account;
  }

  /* Sale Contract Methods */
  async buyPreSale(qtd, rate) {


	//await window.nabox.signMessage(["hello", this.account.toString()])

	  const data = {
		  from: this.account.toString(),
		  value: qtd,
		  contractAddress: CROWDSALE_CONTRACT3,
		  methodName: "buyTokens",
		  methodDesc: "(Address beneficiary, BigDecimal rate) return void",
		  args: [this.account.toString(), rate]
	  }
	  const res = await window.nabox.contractCall(data).then(result => {
			document.getElementById("nswap-amount").value = "";
	  }).catch(error => {
		  console.log(error)

	  });
    // amount
   // return res.result;
  }

	/* Sale Contract Methods */
	async claimv1() {

		const data = {
			from: this.account.toString(),
			value: 0,
			contractAddress: CROWDSALE_CONTRACT,
			methodName: "claimTwenty",
			methodDesc: "() return void",
			args: []
		}
		const res = await window.nabox.contractCall(data)
		// amount
		// return res.result;
	}

	async claimv11() {

		const data = {
			from: this.account.toString(),
			value: 0,
			contractAddress: CROWDSALE_CONTRACT2,
			methodName: "claimTwenty",
			methodDesc: "() return void",
			args: []
		}
		const res = await window.nabox.contractCall(data)
		// amount
		// return res.result;
	}

	async claimv12() {

		const data = {
			from: this.account.toString(),
			value: 0,
			contractAddress: CROWDSALE_CONTRACT3,
			methodName: "claimTwenty",
			methodDesc: "() return void",
			args: []
		}
		const res = await window.nabox.contractCall(data)
		// amount
		// return res.result;
	}

	/* Sale Contract Methods */
	async claimv2() {

		const data = {
			from: this.account.toString(),
			value: 0,
			contractAddress: CROWDSALE_CONTRACT,
			methodName: "claimFirstBatch",
			methodDesc: "() return void",
			args: []
		}
		const res = await window.nabox.contractCall(data)
		// amount
		// return res.result;
	}

	async claimv22() {

		const data = {
			from: this.account.toString(),
			value: 0,
			contractAddress: CROWDSALE_CONTRACT2,
			methodName: "claimFirstBatch",
			methodDesc: "() return void",
			args: []
		}
		const res = await window.nabox.contractCall(data)
		// amount
		// return res.result;
	}

	async claimv32() {

		const data = {
			from: this.account.toString(),
			value: 0,
			contractAddress: CROWDSALE_CONTRACT3,
			methodName: "claimFirstBatch",
			methodDesc: "() return void",
			args: []
		}
		const res = await window.nabox.contractCall(data)
		// amount
		// return res.result;
	}

	/* Sale Contract Methods */
	async claimv3() {

		const data = {
			from: this.account.toString(),
			value: 0,
			contractAddress: CROWDSALE_CONTRACT,
			methodName: "claimSecoundBatch",
			methodDesc: "() return void",
			args: []
		}
		const res = await window.nabox.contractCall(data)
		// amount
		// return res.result;
	}

	/* Sale Contract Methods */
	async claimv3a1() {

		const data = {
			from: this.account.toString(),
			value: 0,
			contractAddress: CROWDSALE_CONTRACT2,
			methodName: "claimSecoundBatch",
			methodDesc: "() return void",
			args: []
		}
		const res = await window.nabox.contractCall(data)
		// amount
		// return res.result;
	}

	/* Sale Contract Methods */
	async claimv3a2() {

		const data = {
			from: this.account.toString(),
			value: 0,
			contractAddress: CROWDSALE_CONTRACT3,
			methodName: "claimSecoundBatch",
			methodDesc: "() return void",
			args: []
		}
		const res = await window.nabox.contractCall(data)
		// amount
		// return res.result;
	}

  async getNulswapBalance() {
	  var xhr = new XMLHttpRequest();
	  xhr.onreadystatechange = function () {
		  if (this.readyState != 4) return;

		  if (this.status == 200) {
			  var data = JSON.parse(this.responseText);
			  console.log(data);
			  localStorage.setItem("mm", data.data.result)

			  document.getElementById("twClaim").innerHTML = parseFloat(data.data.result * 0.2).toFixed(3);
			  document.getElementById("ftClaim").innerHTML = parseFloat(data.data.result * 0.4).toFixed(3);
			  document.getElementById("ft2Claim").innerHTML = parseFloat(data.data.result * 0.4).toFixed(3);;
			  // we get the returned data
			  if(data.data.result > 0 ){
				  document.getElementById("tab").style.display = "block";
			  }else{
				  document.getElementById("tab").style.display = "none";
			  }
			  // Update dos valores no state

		  }

		  // end of state change: it can be after some time (async)
	  };
	  xhr.open("POST", "https://api.nuls.io/api/contract/view", true);


	  xhr.setRequestHeader('Content-Type', 'application/json');
	  xhr.send(JSON.stringify({
		  "contractAddress" : CROWDSALE_CONTRACT,
		  "methodName" : "getTokenBalance",
		  "methodDesc" :  "(Address owner) return BigDecimal",
		  "args" : [this.account.toString()]
	  }));
  }

	async getNulswapBalance2() {

	}

	async getNulswapBalance3() {

	}

	async getCloseStatus() {
		var accc = this.account.toString();
		var xhr = new XMLHttpRequest();
		xhr.onreadystatechange = function () {
			if (this.readyState != 4) return;

			if (this.status == 200) {
				var data = JSON.parse(this.responseText);
				console.log(data.data.result);
				var ig = data.data.result;
				var xhr2 = new XMLHttpRequest();
				xhr2.onreadystatechange = function () {
					if (this.readyState != 4) return;

					if (this.status == 200) {
						var data = JSON.parse(this.responseText);
						console.log(data);// we get the returned data
						if(ig == "true" && data.data.result > 0){
							var element = document.getElementById("firstClaim");
							element.classList.remove("dis");
						}else{
							document.getElementById("firstClaim").setAttribute('disabled', "disabled");
						}
						// Update dos valores no state

					}

					// end of state change: it can be after some time (async)
				};
				xhr2.open("POST", "https://api.nuls.io/api/contract/view", true);


				xhr2.setRequestHeader('Content-Type', 'application/json');
				xhr2.send(JSON.stringify({
					"contractAddress" : CROWDSALE_CONTRACT,
					"methodName" : "getTwentyClaim",
					"methodDesc" :  "(Address beneficiary) return BigDecimal",
					"args" : [accc]
				}));




			}

			// end of state change: it can be after some time (async)
		};
		xhr.open("POST", "https://api.nuls.io/api/contract/view", true);


		xhr.setRequestHeader('Content-Type', 'application/json');
		xhr.send(JSON.stringify({
			"contractAddress" : CROWDSALE_CONTRACT,
			"methodName" : "getClosing",
			"methodDesc" :  "() return boolean",
			"args" : []
		}));
	}
	async getClosses() {
		const data = {
			contractAddress : CROWDSALE_CONTRACT,
			methodName : "getClosingTime",
			methodDesc :  "() return long",
			args : []
		}
		const res = await window.nabox.invokeView(data)
		console.log(res)

		return res.result

	}

	async getBatchClaimStatus() {
		var accc = this.account.toString();
		var xhr = new XMLHttpRequest();
		xhr.onreadystatechange = function () {
			if (this.readyState != 4) return;

			if (this.status == 200) {
				var data = JSON.parse(this.responseText);
				console.log(data.data.result);
				var ij = data.data.result;

				var xhr2 = new XMLHttpRequest();
				xhr2.onreadystatechange = function () {
					if (this.readyState != 4) return;

					if (this.status == 200) {
						var data = JSON.parse(this.responseText);
						console.log(data);
						// we get the returned data
						if(ij == "true" && data.data.result > 0){
							var element = document.getElementById("secoundClaim");
							element.classList.remove("dis");
							document.getElementById("secoundClaim").removeAttribute('disabled');
						}else{
							document.getElementById("secoundClaim").setAttribute('disabled', "disabled");
						}
						// Update dos valores no state

					}

					// end of state change: it can be after some time (async)
				};
				xhr2.open("POST", "https://api.nuls.io/api/contract/view", true);


				xhr2.setRequestHeader('Content-Type', 'application/json');
				xhr2.send(JSON.stringify({
					"contractAddress" : CROWDSALE_CONTRACT,
					"methodName" : "getFortyFirst",
					"methodDesc" :  "(Address beneficiary) return BigDecimal",
					"args" : [accc]
				}));


			}

			// end of state change: it can be after some time (async)
		};
		xhr.open("POST", "https://api.nuls.io/api/contract/view", true);


		xhr.setRequestHeader('Content-Type', 'application/json');
		xhr.send(JSON.stringify({
			"contractAddress" : CROWDSALE_CONTRACT,
			"methodName" : "getFirstbatch",
			"methodDesc" :  "() return boolean",
			"args" : []
		}));
	}

	async get2BatchClaimStatus() {
		var accc = this.account.toString();
		var xhr = new XMLHttpRequest();
		xhr.onreadystatechange = function () {
			if (this.readyState != 4) return;

			if (this.status == 200) {
				var data = JSON.parse(this.responseText);
				console.log(data.data.result);
				var ij = data.data.result;

				var xhr2 = new XMLHttpRequest();
				xhr2.onreadystatechange = function () {
					if (this.readyState != 4) return;

					if (this.status == 200) {
						var data = JSON.parse(this.responseText);
						console.log(data);
						// we get the returned data
						if(ij == "true" && data.data.result > 0){
							var element = document.getElementById("thirdClaim");
							element.classList.remove("dis");
							document.getElementById("thirdClaim").removeAttribute('disabled');
						}else{
							document.getElementById("thirdClaim").setAttribute('disabled', "disabled");
						}
						// Update dos valores no state

					}

					// end of state change: it can be after some time (async)
				};
				xhr2.open("POST", "https://api.nuls.io/api/contract/view", true);


				xhr2.setRequestHeader('Content-Type', 'application/json');
				xhr2.send(JSON.stringify({
					"contractAddress" : CROWDSALE_CONTRACT,
					"methodName" : "getFortySecound",
					"methodDesc" :  "(Address beneficiary) return BigDecimal",
					"args" : [accc]
				}));


			}

			// end of state change: it can be after some time (async)
		};
		xhr.open("POST", "https://api.nuls.io/api/contract/view", true);


		xhr.setRequestHeader('Content-Type', 'application/json');
		xhr.send(JSON.stringify({
			"contractAddress" : CROWDSALE_CONTRACT,
			"methodName" : "getSecoundbatch",
			"methodDesc" :  "() return boolean",
			"args" : []
		}));
	}



	async getEndOfPublicSale(){

		const dataPublicSale = {
			contractAddress: CROWDSALE_CONTRACT,
			methodName: "getClosingTime",
			methodDesc: "() return long",
			args: []
		}

		const resdataPublicSale = await window.nabox.invokeView(dataPublicSale)
		console.log(resdataPublicSale);
		return resdataPublicSale;
	}



	async getNulswapSold() {
		var xhr = new XMLHttpRequest();
		xhr.onreadystatechange = function () {
			if (this.readyState != 4) return;

			if (this.status == 200) {
				var data = JSON.parse(this.responseText);
				let a = data.data.result;
				console.log(data);
				var xhr2 = new XMLHttpRequest();
				xhr2.onreadystatechange = function () {
					if (this.readyState != 4) return;

					if (this.status == 200) {
						var data = JSON.parse(this.responseText);
						console.log(a + "dataq");
						// we get the returned data
						document.getElementById("perSold").style.width =  (parseFloat(data.data.result) + parseFloat(14895356)) / 45000000 * 100 +"%";
						document.getElementById("perTotalSold").innerHTML = parseFloat((parseFloat(data.data.result) + parseFloat(14895356))/ 45000000  * 100).toFixed(2);
						// Update dos valores no state

					}

					// end of state change: it can be after some time (async)
				};
				xhr2.open("POST", "https://api.nuls.io/api/contract/view", true);


				xhr2.setRequestHeader('Content-Type', 'application/json');
				xhr2.send(JSON.stringify({
					"contractAddress" : CROWDSALE_CONTRACT3,
					"methodName" : "getAmountSold",
					"methodDesc" :  "() return BigDecimal",
					"args" : []
				}));

			}

			// end of state change: it can be after some time (async)
		};
		xhr.open("POST", "https://api.nuls.io/api/contract/view", true);


		xhr.setRequestHeader('Content-Type', 'application/json');
		xhr.send(JSON.stringify({
			"contractAddress" : CROWDSALE_CONTRACT,
			"methodName" : "getAmountToSell",
			"methodDesc" :  "() return BigDecimal",
			"args" : []
		}));
	}


  async getRate() {
	  return await 125;
  }
}
