import { useState, useEffect } from "react";
import NulsConnector from "./NulsConnector";

function App() {
    const onChainChange = async chainId => {

        setChain(parseInt(chainId, 16));
        if (parseInt(chainId, 16) !== 0x0) {
            //alert(chainId);
            console.error('You\'re not on Nuls Network!');
            setMsg({ type: "error", msg: "Please select Nuls Network!" });
            setValidChain(false);
        }
        else {
            setMsg({ type: "", msg: "" });
           // await bsc.reloadContracts();
           // setNBalance(await bsc.getNulswapBalance());
            setBNBPAURate(await bsc.getRate());
            setValidChain(true);
        }
    }

    const onAccountsChange = accounts => {
        setAccount(accounts[0]);
        console.log(`Address changed to ${accounts[0]}`);
        bsc.setAccount(accounts[0]);
        bsc.getNulswapBalance();

    }



  const [NswapAmount, setNAmount] = useState(0);
  const [BNBAmount, setBNBAmount] = useState(0);

  const [NBalance, setNBalance] = useState(0);

  const [BNBPAURate, setBNBPAURate] = useState(0);
  const [BNBUSDTRate, setNulswapUSDTRate] = useState(0);

  const [msg, setMsg] = useState({ type: '', msg: '' });

  const [chain, setChain] = useState(0);
  const [validChain, setValidChain] = useState(true);

  const [account, setAccount] = useState("");
  const [validAccount, setValidAccount] = useState(true);

  const [bsc, setBsc] = useState(new NulsConnector());







  const buyBlocker = () => (!validAccount || !validChain ? true : false);

  const msgStyle = () => {
    let classes = '';
    switch (msg.type) {
      case 'success':
        classes = "bg-green-300 py-2 px-3 rounded-lg border border-green-500 font-medium text-white"
        break
      case 'error':
        classes = "bg-red-300 py-2 px-3 rounded-lg border border-red-500 font-medium text-white"
        break
      case 'warning':
        classes = "bg-yellow-300 py-2 px-3 rounded-lg border border-yellow-500 font-medium text-white"
        break
      case 'info':
        classes = "bg-blue-300 py-2 px-3 rounded-lg border border-blue-500 font-medium text-white";
        break;
      default:
        classes = "";
    }

    return classes;
  }

  // Efeito ao iniciar o app
     useEffect(() => {
    try {
        const loafingFunc = async () => {
            setNulswapUSDTRate(parseFloat((await (await fetch("https://api.binance.com/api/v3/ticker/price?symbol=NULSUSDT")).json()).price).toFixed(4) );
        }
        loafingFunc();
    }
    catch (error) {
      if (error === "Must install Metamask") {
        setMsg({ type: 'error', msg: 'Please install a Web3 wallet' });
      } else {
        console.error(error);
      }
    }
  }, []);

    const func = async () => {
        document.getElementById("btnBuy").innerHTML = "Loading...";
        setBsc(await bsc.initialize());

        setNulswapUSDTRate(parseFloat((await (await fetch("https://api.binance.com/api/v3/ticker/price?symbol=NULSUSDT")).json()).price).toFixed(4) );

        const naboxInfo = await window.nabox.createSession();
        document.getElementById("btnBuy").style.display = "none";
        document.getElementById("btnBuy2").style.display = "block";
        //alert(naboxInfo);

        //console.log(naboxInfo)
        //alert(naboxInfo);
        // Check account
        let acc = await bsc.getAccount(0);
        setAccount(acc);
        //alert(bsc.account[0]);
        console.log(bsc);
        console.log(`Address is ${acc}`);

        // Check chain
        let chainId = await bsc.getChainID();
        setChain(chainId);
        if (parseInt(chainId, 16) !== 0x0) {
            setMsg({ type: "error", msg: "Please select Nuls Network!" });
            setValidChain(false);
        }
        await bsc.getNulswapSold()
        // alert(amountSold);


        await bsc.getNulswapBalance();



       // console.log(nulswapAmount);
        //setNBalance(nulswapAmount);
        console.log("statusOpen");
        let element;
        let status;
        await bsc.getCloseStatus()
        await bsc.getBatchClaimStatus()

     //   await bsc.get2BatchClaimStatus()

     //   await bsc.get3BatchClaimStatus()

        func3();
        // setBNBPAURate(await bsc.getRate());
    }







    //every 10 seconds very the amount sold
    const func3 = async () => {
        var i = 1;
        const x = setInterval( async function() {
            let amountSold = 0;
            if (typeof window.nabox !== "undefined") {
                await bsc.getNulswapSold()
                let am = await bsc.getNulswapBalance()
                await   bsc.getNulswapBalance2();
                await bsc.getNulswapBalance3();
                //setNBalance(am);
            }
        }, 2000)
    }

    // func3();

    //every 10 seconds very the amount sold
    const func2 = async () => {

        let timestampDate = await  bsc.getClosses();
        //alert(timestampDate)
   /*     if (typeof window.nabox !== "undefined") {
            timestampDate = await bsc.getEndOfPublicSale();
        }else{
            timestampDate = 1;
        }*/
        console.log("ll")
        let countDownDate = timestampDate * 1000;
        // Update the count down every 1 second
        const x = setInterval(function() {

            // Get today's date and time
            let now = new Date().getTime();

            // Find the distance between now and the count down date
            let distance = countDownDate - now;
            // Time calculations for days, hours, minutes and seconds
            var days = Math.floor(distance / (1000 * 60 * 60 * 24));
            var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((distance % (1000 * 60)) / 1000);

            // Display the result in the element with id="demo"
            document.getElementById("demo").innerHTML = days + "d " + hours + "h "
                + minutes + "m " + seconds + "s ";

            // If the count down is finished, write some text
            if (distance < 0) {
                clearInterval(x);
                console.log("dsdwdw");

                document.getElementById("demo").innerHTML = "On Going";
            }else {


            }
        }, 1000);

    }
     func2();

  const click = async (e) => {
      if (typeof window.nabox !== "undefined") {
          func();
          document.getElementById("btnBuy").onclick = clickBuy;
          document.getElementById("percentage").style.display = "block";

          window.nabox.on('chainChanged', onChainChange);
          window.nabox.on('accountsChanged', onAccountsChange);
      } else {
          setValidAccount(false);
          setValidChain(false);
          setMsg({type: 'error', msg: 'Please install Nabox wallet to participate!'});
      }

  };

    const claim1Batch = async () => {
        await bsc.claimv1();
    };

    const claim1Batch1 = async () => {
        await bsc.claimv11();
    };

    const claim1Batch2 = async () => {
        await bsc.claimv12();
    };

    const claim2Batch = async () => {
        await bsc.claimv2();
    };

    const claim22Batch = async () => {
        await bsc.claimv22();
    };

    const claim32Batch = async () => {
        await bsc.claimv32();
    };

    const claim3Batch = async () => {
        await bsc.claimv3();
    };

    const claim3a1Batch = async () => {
        await bsc.claimv3a1();
    };

    const claim3a2Batch = async () => {
        await bsc.claimv3a2();
    };


    const clickBuy = async (e) => {
        let rgghr = 0;
        if(typeof NBalance === "undefined"){
            rgghr = 0;
        }else{

            rgghr = parseFloat(NBalance);
        }
        let na = parseFloat(NswapAmount) + parseFloat(rgghr);
        if(BNBAmount > 0 && na <= 45000000){
            setBNBAmount(parseInt(BNBAmount));
            setNAmount(parseFloat(BNBAmount  * (parseFloat(BNBUSDTRate) - parseFloat("0.006")) * 125).toFixed(3) );
            await bsc.buyPreSale(BNBAmount, (parseFloat(BNBUSDTRate) - parseFloat("0.006")));
           // document.getElementById("perTotalSold").innerHTML = parseFloat(data.data.result / a  * 100).toFixed(2);

            let am = await bsc.getNulswapBalance();
            let iam =  await   bsc.getNulswapBalance2();
            let iam2 = await   bsc.getNulswapBalance3();

            document.getElementById("nswapBalance").innerHTML = am;
            if(am > 0 || iam > 0 || iam2 > 0){
                document.getElementById("tab").style.display = "block";
            }
           // alert(am);
            // Update dos valores no state
            setNBalance(am);
        }else{
            console.log(BNBAmount + " "+NswapAmount + " " + NBalance+ " "+na);
            alert('Amount is 0 or bigger than the maximum allocation ($500)');
        }

    };

  const changeBNBInput = async (e) => {
    setBNBAmount(parseInt(e.target.value));
    setNAmount(parseFloat(e.target.value  * (parseFloat(BNBUSDTRate) - parseFloat("0.006")) * 125).toFixed(3) );
  }

  const NSWAPChanger = async (e) => {
    setNAmount(e.target.value);
    setBNBAmount((e.target.value / (parseFloat(BNBUSDTRate) - parseFloat("0.006")) / 125).toFixed(3));
  }

  return (

    <div id="block1" className="flex flex-col items-center justify-center w-full  to-white h-screen">
      <div id="block2" className="App container shadow-lg rounded-2xl px-6 py-4 flex flex-col w-96 h-content bg-white">
        <a href="https://nulswap.com/" className="mb-5 mt-2 w-52 self-center">
          <img src="/nLogoTicker.png" alt="TPAU Logo" />
        </a>
        <div id="msg" className={msgStyle()}>
          <p>{msg.msg}</p>
        </div>
        { /*
        <p>Chain ID: {chain}</p>
        <p>Address: {address}</p>
        <p>You have {NBalance} $TPAU</p>
        <p>You have {BNBBalance} BNB</p>
        <p>1 BNB = {BNBPAURate} $TPAU</p>
        */ }
        <div className="flex flex-row justify-between items-center">
          <h1 className="font-medium my-2">Buy NSWAP</h1>
          <p className="text-sm text-gray-500 font-medium">Balance: <span id="nswapBalance">{NBalance}</span>  NSWAP</p>
        </div>
        <div className="flex flex-col my-2 border border-gray-200 py-2 px-4 rounded-lg">
          <div className="flex flex-row justify-between items-center">
            <input
              id="bnb-amount"
              type="number"
              value={BNBAmount}
              min={0}
              placeholder={0}
              style={{outline:"none"}}
              step="1"
              onChange={changeBNBInput}
              className="w-3/6 text-lg"
              readOnly={buyBlocker()}
            />
            <div className="flex flex-row w-1/3 items-center justify-end">
              <img src="/nuls-circ.png" className="w-9 h-9 mr-2" alt="" />
              <p className="font-medium">NULS</p>
            </div>
          </div>
          <p className="text-sm text-gray-500">Aprox. {((parseFloat(BNBUSDTRate) - parseFloat("0.006")) * BNBAmount).toFixed(4)} USD</p>
        </div>
        <p className="text-center">to</p>
        <div className="flex flex-row items-center my-2 justify-between border border-gray-200 py-2 px-4 rounded-lg">
          <input
            id="nswap-amount"
            type="number"
            value={NswapAmount}
            min={0}
            placeholder={0}
            onChange={NSWAPChanger}
            disabled="disabled"
            className="w-3/6 text-lg"
            readOnly={buyBlocker()}
          />
          <div className="flex flex-row w-1/3 align-center justify-end">
            <img class="nLogoTicker" src="/nswap-circ.png" className="h-9 w-9 mr-2" alt="" />
            <p className="font-medium" style={{marginTop:"5px"}}>NSWAP</p>
          </div>
        </div>
        <button id="btnBuy" onClick={click} className="bg-yellow-pau py-3 px-3 rounded font-bold text-white my-2 hover:ring-4 ring-yellow-200" disabled={buyBlocker()}>Connect Wallet</button>
          <button id="btnBuy2" onClick={clickBuy} className="bg-yellow-pau py-3 px-3 rounded font-bold text-white my-2 hover:ring-4 ring-yellow-200" disabled={buyBlocker()}>Buy {NswapAmount} NSWAP</button>
          <div id="percentage" className="percentage">
        <p className="publicSale">Whitelist <span id="perTotalSold"></span>% Filled - <span id="demo"></span></p>
        <div className="amountSold"><div id="perSold" className="perSold"></div></div>
       </div>
          <table id="tab" style={{fontSize:"12px"}}>
          <tr>
              <th width="45%">Amount</th>
              <th width="40%">Date</th>
              <th width="15%">Claim</th>
          </tr>
              <tr>
                  <td>20% - <span id="twClaim">{parseFloat(NBalance * 0.2).toFixed(2)}</span>  NSWAP</td>
                  <td>Sold out/timer ends</td>
                  <td><button id="firstClaim" className="btnClaim dis" onClick={claim1Batch} >Claim</button></td>
              </tr>
          <tr>
              <td>40% - <span id="ftClaim">{parseFloat(NBalance * 0.4).toFixed(2)}</span> NSWAP</td>
              <td>1 month after</td>
              <td><button id="secoundClaim" className="btnClaim dis" onClick={claim2Batch} >Claim</button></td>
          </tr>
          <tr>

              <td>40% - <span id="ft2Claim">{parseFloat(NBalance * 0.4).toFixed(2)}</span> NSWAP</td>
              <td>2 months after</td>
              <td><button id="thirdClaim" className="btnClaim dis" onClick={claim3Batch} >Claim</button></td>
          </tr>
          </table>


      </div>
        <div className="socialNetworks">


            <div className="pad"><a target="_blank" href="https://t.me/nulswap"><i className="fab fa-telegram-plane"></i></a></div>
            <div className="pad"><a target="_blank" href="https://twitter.com/nulswap"><i className="fab fa-twitter"></i></a></div>
          <div className="pad"><a target="_blank" href="https://discord.com/invite/3HZCbypWHF"> <i className="fab fa-discord"></i></a></div>
            <div className="pad"><a target="_blank" href="https://nulswap.medium.com/"><i className="fab fa-medium-m"></i></a></div>

          <div className="pad"><a target="_blank" href="https://docs.nulswap.com"> <i className="fas fa-book"></i></a></div>


           </div>


    </div>


  );
}



export default App;
